import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { Page } from "../../shared/Page/Page"
import { Header } from "../../shared/Header/Header"
import { Footer } from "../../shared/Footer/Footer"
import { H1, H2 } from "../../shared/Ui/Typography/Typography"
import { Link } from "gatsby"
import media from "css-in-js-media"
//https://images.unsplash.com/photo-1484863137850-59afcfe05386?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2102&q=80
const Container = styled.div`
    background-image: linear-gradient(
        to left top,
        #e0e7ff,
        #e2eaff,
        #e4edff,
        #e7f0ff,
        #eaf3ff,
        #edf5ff,
        #f1f6ff,
        #f4f8ff,
        #f7faff,
        #fbfbff,
        #fdfdff,
        #ffffff
    );

    box-shadow: ${({ theme }) => theme.surfaces.light} 0 -50rem 50rem -50px
        inset;

    .content section.top {
        padding: 100rem 0 0;
    }

    .buttons {
        display: flex;
        gap: 40rem;
        padding: 100rem 0;

        ${media("<desktop")} {
            flex-direction: column;
        }

        a {
            flex: 1;
        }

        .button {
            flex: 1;
            display: flex;
            border-radius: 10rem;
            background: ${({ theme }) => theme.surfaces.lighter};
            box-shadow: rgba(50, 50, 93, 0.25) 0 50rem 100rem -20rem,
                rgba(0, 0, 0, 0) 0px 30px 60px -30rem;
            transition: all ease-in-out 150ms;
            outline: none;
            overflow: hidden;

            &:hover {
                box-shadow: rgba(50, 50, 93, 0.25) 0 50rem 100rem -20rem,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30rem;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                padding: 0 50rem;
                transform: translateX(-60rem);

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: ${({ theme }) => theme.surfaces.light};
                    border-radius: 90%;
                    min-width: 90rem;
                    width: 90rem;
                    height: 90rem;
                }

                svg {
                    width: 50rem;
                    height: 50rem;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 10rem;
                padding: 50rem 50rem 50rem 0;
                transform: translateX(-60rem);

                .title {
                    display: flex;
                    align-items: center;
                    font-family: Gilroy-SemiBold, sans-serif;
                    color: ${({ theme }) => theme.text.dark};
                    font-size: 25rem;

                    .bottom-link-chevron {
                        position: relative;
                        top: 2rem;
                    }
                }

                .description {
                    color: ${({ theme }) => theme.text.light};
                    font-size: 16rem;
                    line-height: 25rem;
                }
            }
        }
    }
`

const Partnerships = ({ location }) => {
    return (
        <Page disableHeader disableFooter disableCta location={location}>
            <Helmet>
                <title>Partnerships</title>
            </Helmet>
            <Container className={"overlapPageMargins"}>
                <Header constrained />

                <div className={"content"}>
                    <section className={"top pageConstrained"}>
                        <H1>Partnerships 🤝</H1>
                        <H2>
                            Explore opportunities and offers to work with
                            23shout.
                        </H2>
                    </section>
                    <div className={"buttons pageConstrained"}>
                        <Link
                            to={"/company/partnerships/industry"}
                            tabIndex={-1}
                        >
                            <div className={"button"}>
                                <div className={"icon"}>
                                    <div style={{ background: "#86EFAC" }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#FFF"
                                        >
                                            <g>
                                                <rect
                                                    fill="none"
                                                    height="24"
                                                    width="24"
                                                />
                                                <rect
                                                    fill="none"
                                                    height="24"
                                                    width="24"
                                                />
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M9,15c-2.67,0-8,1.34-8,4v1c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-1C17,16.34,11.67,15,9,15z" />
                                                    <path d="M4.74,9h8.53c0.27,0,0.49-0.22,0.49-0.49V8.49c0-0.27-0.22-0.49-0.49-0.49H13c0-1.48-0.81-2.75-2-3.45V5.5 C11,5.78,10.78,6,10.5,6S10,5.78,10,5.5V4.14C9.68,4.06,9.35,4,9,4S8.32,4.06,8,4.14V5.5C8,5.78,7.78,6,7.5,6S7,5.78,7,5.5V4.55 C5.81,5.25,5,6.52,5,8H4.74C4.47,8,4.25,8.22,4.25,8.49v0.03C4.25,8.78,4.47,9,4.74,9z" />
                                                    <path d="M9,13c1.86,0,3.41-1.28,3.86-3H5.14C5.59,11.72,7.14,13,9,13z" />
                                                    <path d="M21.98,6.23l0.93-0.83l-0.75-1.3l-1.19,0.39c-0.14-0.11-0.3-0.2-0.47-0.27L20.25,3h-1.5L18.5,4.22 c-0.17,0.07-0.33,0.16-0.48,0.27L16.84,4.1l-0.75,1.3l0.93,0.83C17,6.4,17,6.58,17.02,6.75L16.09,7.6l0.75,1.3l1.2-0.38 c0.13,0.1,0.28,0.18,0.43,0.25L18.75,10h1.5l0.27-1.22c0.16-0.07,0.3-0.15,0.44-0.25l1.19,0.38l0.75-1.3l-0.93-0.85 C22,6.57,21.99,6.4,21.98,6.23z M19.5,7.75c-0.69,0-1.25-0.56-1.25-1.25s0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25 S20.19,7.75,19.5,7.75z" />
                                                    <path d="M19.4,10.79l-0.85,0.28c-0.1-0.08-0.21-0.14-0.33-0.19L18.04,10h-1.07l-0.18,0.87c-0.12,0.05-0.24,0.12-0.34,0.19 l-0.84-0.28l-0.54,0.93l0.66,0.59c-0.01,0.13-0.01,0.25,0,0.37l-0.66,0.61l0.54,0.93l0.86-0.27c0.1,0.07,0.2,0.13,0.31,0.18 L16.96,15h1.07l0.19-0.87c0.11-0.05,0.22-0.11,0.32-0.18l0.85,0.27l0.54-0.93l-0.66-0.61c0.01-0.13,0.01-0.25,0-0.37l0.66-0.59 L19.4,10.79z M17.5,13.39c-0.49,0-0.89-0.4-0.89-0.89c0-0.49,0.4-0.89,0.89-0.89s0.89,0.4,0.89,0.89 C18.39,12.99,17.99,13.39,17.5,13.39z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className={"text"}>
                                    <div
                                        className={"title"}
                                        style={{ color: "#10B981" }}
                                    >
                                        Industry partners <Chevron />
                                    </div>
                                    <div className={"description"}>
                                        Resale solutions for phone system
                                        suppliers and industry professionals.
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to={"/company/partnerships/technology"}
                            tabIndex={-1}
                        >
                            <div className={"button"}>
                                <div className={"icon"}>
                                    <div style={{ background: "#A5B4FC" }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#FFF"
                                        >
                                            <g>
                                                <rect
                                                    fill="none"
                                                    height="24"
                                                    width="24"
                                                />
                                            </g>
                                            <g>
                                                <g>
                                                    <circle
                                                        cx="20"
                                                        cy="12"
                                                        r="2"
                                                    />
                                                    <circle
                                                        cx="4"
                                                        cy="12"
                                                        r="2"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="20"
                                                        r="2"
                                                    />
                                                    <path d="M7.89,14.65l-2.94,2.93c-0.39,0.39-0.39,1.02,0,1.41s1.02,0.39,1.41,0l2.94-2.93c0.39-0.38,0.39-1.02,0-1.41 C8.91,14.26,8.28,14.26,7.89,14.65z" />
                                                    <path d="M6.41,4.94C6.02,4.55,5.39,4.55,5,4.94C4.61,5.33,4.61,5.96,5,6.35l2.93,2.94c0.39,0.39,1.02,0.39,1.42,0 C9.73,8.9,9.73,8.27,9.34,7.88L6.41,4.94z" />
                                                    <path d="M16.12,14.65c-0.39-0.39-1.02-0.39-1.42,0c-0.39,0.39-0.39,1.02,0,1.41L17.64,19c0.39,0.39,1.02,0.39,1.41,0 s0.39-1.02,0-1.41L16.12,14.65z" />
                                                    <path d="M16.06,9.33l2.99-2.98c0.39-0.4,0.39-1.03,0-1.42c-0.39-0.39-1.02-0.39-1.41,0l-2.99,2.98c-0.39,0.39-0.39,1.02,0,1.42 C15.04,9.72,15.67,9.72,16.06,9.33z" />
                                                    <circle
                                                        cx="12"
                                                        cy="4"
                                                        r="2"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className={"text"}>
                                    <div
                                        className={"title"}
                                        style={{ color: "#818CF8" }}
                                    >
                                        Technology partners <Chevron />
                                    </div>
                                    <div className={"description"}>
                                        Supply and integration opportunities for
                                        external products and out of the 23shout
                                        platform.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </Container>
            <Footer constrained />
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default Partnerships
